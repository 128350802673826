import { ApiService } from '@conrati/utils';

const trackingEndpoint = '/v2/identify';

// Tracking service
function TrackingApiService(eventProperties: { [key: string]: unknown }): void {
	ApiService.ApiRequest('POST', trackingEndpoint, eventProperties);
}

function TrackAddCardEvent(userId: string): void {
	ApiService.ApiRequest('POST', '/v1.1/payment/track-card-added', {
		user_id: userId,
	});
}

export { TrackingApiService, TrackAddCardEvent };
