/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { useLocation } from 'react-router';
import InputRange from 'react-input-range';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import { reactSelectStyle } from '../../config';
import closeIcon from '../../assets/close-icon.svg';
import './input-range.css';
import { enumerateDaysBetweenDatesSearch, getQueryParams } from '../../utils';

const popularSkillList = [
  'Depression',
  'Anxiety',
  'Burnout',
  'Trauma',
  'Couples Counsellor',
  'Psychologist',
  'Counsellor',
];

const rateFilterData = {
  GBP: [
    {
      value: 'u50',
      label: 'Under £50',
    },
    {
      value: 'u100',
      label: 'Under £100',
    },
    {
      value: 'u150',
      label: 'Under £150',
    },
  ],
  EUR: [
    {
      value: 'u50',
      label: 'Under €50',
    },
    {
      value: 'u100',
      label: 'Under €100',
    },
    {
      value: 'u150',
      label: 'Under €150',
    },
  ],
  ZAR: [
    {
      value: 'u50',
      label: 'Under R500',
    },
    {
      value: 'u100',
      label: 'Under R1000',
    },
    {
      value: 'u150',
      label: 'Under R1500',
    },
  ],
};

const rateSliderRange = {
  GBP: {
    min: 0,
    max: 300,
  },
  EUR: {
    min: 0,
    max: 300,
  },
  ZAR: {
    min: 0,
    max: 6500,
  },
};

function SearchFilter({ onHandleOnFilterSelect, recentSkills, currency, history }) {
  const location = useLocation();

  const masterData = useSelector((state) => state.masterdata.masterData);
  const [freeFirstSession, setFreeFirstSession] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [selectedRateFilter, setSelectedRateFilter] = useState({
    val: '',
    label: '',
    searchKey: '',
  });
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    val: '',
    label: '',
    searchKey: '',
  });
  const [selectedCount, setSelectedCount] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [selectedRateSort, setSelectedRateSort] = useState('');

  const activeRateFilter = rateFilterData[currency.currencyCode];
  const activeRateRange = rateSliderRange[currency.currencyCode];

  const [sliderSettings, setSliderSettings] = useState({
    max: activeRateRange ? activeRateRange.max : 0,
    min: 0,
  });

  useEffect(() => {
    if (location.search) {
      const paramsPath = new URLSearchParams(location.search);
      const skillsParams = paramsPath.has('skills') ? paramsPath.get('skills').split(',') : [];

      const languageParams = paramsPath.has('languages')
        ? paramsPath.get('languages').split(',')
        : [];

      const countriesParams = paramsPath.has('countries')
        ? paramsPath.get('countries').split(',')
        : [];

      if (!paramsPath.has('date')) {
        setSelectedDateFilter({
          val: '',
          label: '',
          searchKey: '',
        });

        setSelectedDateRange([
          {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          },
        ]);
      }

      if (paramsPath.has('rate')) {
        const rateRange = paramsPath.get('rate').split(',');

        if (rateRange.length > 1) {
          setSliderSettings({
            max: Number(rateRange[1]),
            min: Number(rateRange[0]),
          });

          const filterData = {
            value: `${currency.currencySymbol}${rateRange[0]} - ${currency.currencySymbol}${rateRange[1]}`,
            label: `${currency.currencySymbol}${rateRange[0]} - ${currency.currencySymbol}${rateRange[1]}`,
            searchKey: `${currency.currencySymbol}${rateRange[0]} - ${currency.currencySymbol}${rateRange[1]}`,
          };

          setSelectedRateFilter(filterData);
        }
      } else {
        setSelectedRateFilter({
          val: '',
          label: '',
          searchKey: '',
        });
      }

      if (paramsPath.has('ratefilter')) {
        const ratefilterParamsVal = paramsPath.get('ratefilter');
        
        if (!ratefilterParamsVal) {
            setSelectedRateSort('');
            setFreeFirstSession(false);
        }

        if (ratefilterParamsVal === 'free') {
          setFreeFirstSession(true);
          setSelectedRateSort(true);
        }

        if (ratefilterParamsVal === 'u50') {
          setSelectedRateSort('u50');
        }

        if (ratefilterParamsVal === 'u100') {
          setSelectedRateSort('u100');
        }

        if (ratefilterParamsVal === 'u150') {
          setSelectedRateSort('u150');
        }
      } else {
        setFreeFirstSession(false);
        setSelectedRateSort('');
      }

      const selectedSkills = skillsParams
        .filter((preval) => preval.length > 0)
        .map((val) => ({
          value: val,
          label: val,
          searchKey: val,
        }));

      const selectedLanguage = languageParams
        .filter((preval) => preval.length > 0)
        .map((val) => ({
          value: val,
          label: val,
          searchKey: val,
        }));

      const selectedCountries = countriesParams
        .filter((preval) => preval.length > 0)
        .map((val) => ({
          value: val,
          label: val,
          searchKey: val,
        }));

      setSelectedSkill(selectedSkills);
      setSelectedLang(selectedLanguage);
      setSelectedCount(selectedCountries);
    }
  }, [location.search]);

  const languages = masterData.languages.map((lang) => ({
    value: lang.language,
    label: lang.language,
    code: lang.code,
    searchKey: lang.language.toLowerCase(),
  }));

  const countries = masterData.countries.map((country) => ({
    value: country.countryName,
    label: country.countryName,
    code: country.countryCode,
    currencyCode: country.currencyCode,
    searchKey: country.countryName.toLowerCase(),
  }));

  const skillsData = [];

  masterData.skills.forEach((skill) => {
    if (skill.skillName) {
      skillsData.push({
        value: skill.skillName,
        label: skill.skillName,
        searchKey: skill.searchKey,
      });
    }
  });

  const onClearSkill = (skillName) => {
    const currentState = [...selectedSkill];
    const index = currentState.findIndex((skill) => skill.label === skillName);
    currentState.splice(index, 1);

    onHandleOnFilterSelect('skills', currentState, true);
    setSelectedSkill(currentState);
  };

  const onClearLanguage = (langName) => {
    const currentState = [...selectedLang];
    const index = currentState.findIndex((lang) => lang.label === langName);
    currentState.splice(index, 1);

    onHandleOnFilterSelect('languages', currentState);
    setSelectedLang(currentState);
  };

  const onClearCountry = (countryName) => {
    const currentState = [...selectedCount];
    const index = currentState.findIndex((country) => country.label === countryName);
    currentState.splice(index, 1);

    onHandleOnFilterSelect('countries', currentState);
    setSelectedCount(currentState);
  };

  const onHandleRateFilterChanges = () => {
    const filterData = {
      value: `${sliderSettings.min},${sliderSettings.max}`,
      label: `${currency.currencySymbol}${sliderSettings.min} - ${currency.currencySymbol}${sliderSettings.max}`,
      searchKey: `${sliderSettings.min},${sliderSettings.max}`,
    };

    onHandleOnFilterSelect('rate', filterData);
  };

  const extractNumber = (text) => {
	// Match one or more digits
	const match = text.match(/\d+/);
	
	// Convert the matched string to a number, or return 0
	return match ? Number(match[0]) : 0;
  }

  const updateSliderValue = (rangeValue) => {
    let maxValue = extractNumber(rangeValue.label) || activeRateRange.max;

    setSliderSettings({
        max: maxValue,
        min: 0,
      });
  }

  const onHandleFilterItemChange = (rateFilter, firstSessionFree) => {
    if (
      selectedRateSort === rateFilter.value ||
      (rateFilter.value === 'free' && selectedRateSort)
    ) {
      onHandleOnFilterSelect('ratefilter', '');
      return setSelectedRateSort(0);
    }

    updateSliderValue(rateFilter);

    const rateSort = rateFilter.value === 'free' ? firstSessionFree : rateFilter.value;

    setSelectedRateSort(rateSort);

    onHandleOnFilterSelect('ratefilter', rateFilter.value);

    const filterData = {
      value: rateFilter.value,
      label: rateFilter.label,
      searchKey: rateFilter.value,
    };

    setSelectedRateFilter(filterData);
  };

  const onDateFilter = (dateRange) => {
    const startDate = moment(dateRange.startDate);
    const endDate = moment(dateRange.endDate);

    if (startDate.isSameOrBefore(endDate)) {
      const dateSearchKey = enumerateDaysBetweenDatesSearch(dateRange.startDate, dateRange.endDate);
      setSelectedDateFilter({
        val: `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`,
        label: `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`,
        searchKey: `${startDate.format('D MMM')} - ${endDate.format('D MMM')}`,
      });
      onHandleOnFilterSelect('date', dateSearchKey);
    }
  };

  const onClearRangeChip = () => {
    setSelectedRateFilter({
      val: '',
      label: '',
      searchKey: '',
    });

    setSliderSettings({
      max: activeRateRange.max,
      min: 0,
    });

    onHandleOnFilterSelect('rate', {
      val: '',
      label: '',
      searchKey: '',
    });
  };

  const onClearRateFilter = () => {
    setSelectedRateSort('');
    onHandleOnFilterSelect('ratefilter', '');
  };

  const onClearRateChecks = () => {
    onClearRangeChip()
    onClearRateFilter();
  };

  const onSearchSkill = (ev) => {
    setSelectedSkill((state) => {
      const newSkills = state.filter((value) => value.searchKey !== 'popular-skill');

      onHandleOnFilterSelect('skills', [...newSkills, ev]);
      return [...newSkills, ev];
    });
  };

  const onLinkClick = (event) => {
    event.preventDefault();

    const { text, search, pathname } = event.target;
    const href = `${pathname}${search}`;

    setSelectedSkill([
      {
        value: href,
        label: text.toLowerCase(),
        searchKey: 'popular-skill',
      },
    ]);

    history.replace(href);
  };

  return (
    <div className={style.SearchFilter}>
      {/* Skills filter section */}
      {/* ********************** */}
      {/* Skills filter section */}

      <div className={style.filterGroup}>
        <h6 className={style.filterName}>SKILL</h6>

        <div className={style.selectedList}>
          {selectedSkill.map((skill, ind) => (
            <button
              key={`skill-li-${ind}`}
              type="submit"
              className={style.selectedItem}
              onClick={() => onClearSkill(skill.label)}
            >
              {skill.label}
              <span>
                <img src={closeIcon} alt="Close Icon" />
              </span>
            </button>
          ))}
        </div>

        <Select
          controlShouldRenderValue={false}
          onChange={(ev) => {
            onSearchSkill(ev);
          }}
          styles={reactSelectStyle}
          options={skillsData}
          placeholder="Search for Skill"
        />
        <div className={style.filterItem}>
          <h6 className={style.filterName}>POPULAR SKILLS:</h6>
          <div className={style.list}>
            {popularSkillList.map((skill, ind) => (
              <a
                key={`index-${ind}`}
                href={`/search?query=${getQueryParams(
                  location.search
                )}&skills=${skill.toLowerCase()}`}
                className={style.btn}
                onClick={onLinkClick}
              >
                {skill}
              </a>
            ))}
          </div>
        </div>
        {recentSkills.length > 0 && (
          <div className={style.filterItem}>
            <h6 className={style.filterName}>RECENT SKILLS:</h6>
            <div className={style.list}>
              {recentSkills.map((skill, ind) => (
                <a
                  key={`index-${ind}`}
                  href={`/search?skills=${skill.toLowerCase()}`}
                  className={style.btn}
                  onClick={onLinkClick}
                >
                  {skill}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* End Skills filter section */}
      {/* ********************** */}
      {/* End Skills filter section */}

      {/* Rate filter section */}
      {/* ********************** */}
      {/* Rate filter section */}

      <div className={style.filterGroup}>
        <h6 className={style.filterName}>RATE</h6>

        <div className={style.selectedList}>
          {selectedRateFilter.label && (
            <button
              key={`rate-li-${selectedRateFilter.label}`}
              type="submit"
              className={style.selectedItem}
              onClick={() => {
                onClearRateChecks();
              }}
            >
              {selectedRateFilter.label}
              <span>
                <img src={closeIcon} alt="Close Icon" />
              </span>
            </button>
          )}
        </div>

        <div className={style.rangeSlider}>
          <InputRange
            maxValue={activeRateRange.max}
            minValue={0}
            value={{ max: sliderSettings.max, min: sliderSettings.min }}
            onChange={(ev) =>
              setSliderSettings({
                max: ev.max,
                min: ev.min,
              })
            }
            onChangeComplete={() => {
              onHandleRateFilterChanges();
              onClearRateFilter();
            }}
            step={5}
            formatLabel={(v) => {
              return `${currency.currencySymbol} ${v}`;
            }}
          />
        </div>

        <div className={style.optionsRate}>
          <div className={style.option}>
            <input
              type="checkbox"
              name="free-session"
              checked={selectedRateSort === freeFirstSession}
              onChange={() => {
                onClearRangeChip();
                onHandleFilterItemChange(
                  {
                    value: 'free',
                    label: 'Free First Session',
                  },
                  freeFirstSession
                );
              }}
            />
            <p className={style.optionText}>Free First Session</p>
          </div>
          {activeRateFilter.map((rateFilter) => (
            <div className={style.option} key={rateFilter.label}>
              <input
                type="checkbox"
                name="free-session"
                checked={selectedRateSort === rateFilter.value}
                onChange={() => {
                  onClearRangeChip();
                  onHandleFilterItemChange(rateFilter);
                }}
              />
              <p className={style.optionText}>{rateFilter.label}</p>
            </div>
          ))}
        </div>
      </div>

      {/* End Rate filter section */}
      {/* ********************** */}
      {/* End Rate filter section */}

      {/* Language filter section */}
      {/* ********************** */}
      {/* Language filter section */}

      <div className={style.filterGroup}>
        <h6 className={style.filterName}>Language</h6>

        <div className={style.selectedList}>
          {selectedLang.length > 0 &&
            selectedLang.map((lang, ind) => (
              <button
                key={`lang-li-${ind}`}
                type="submit"
                className={style.selectedItem}
                onClick={() => onClearLanguage(lang.label)}
              >
                {lang.label}
                <span>
                  <img src={closeIcon} alt="Close Icon" />
                </span>
              </button>
            ))}
        </div>

        <Select
          controlShouldRenderValue={false}
          onChange={(ev) =>
            setSelectedLang((state) => {
              onHandleOnFilterSelect('languages', [...state, ev]);
              return [...state, ev];
            })
          }
          styles={reactSelectStyle}
          options={languages}
          placeholder="Search for Language"
        />
      </div>

      {/* End Language filter section */}
      {/* ********************** */}
      {/* End Language filter section */}

      {/* Country filter section */}
      {/* ********************** */}
      {/* Country filter section */}

      <div className={style.filterGroup}>
        <h6 className={style.filterName}>Country</h6>

        <div className={style.selectedList}>
          {selectedCount.length > 0 &&
            selectedCount.map((country, ind) => (
              <button
                key={`lang-li-${ind}`}
                type="submit"
                className={style.selectedItem}
                onClick={() => onClearCountry(country.label)}
              >
                {country.label}
                <span>
                  <img src={closeIcon} alt="Close Icon" />
                </span>
              </button>
            ))}
        </div>

        <Select
          controlShouldRenderValue={false}
          onChange={(ev) =>
            setSelectedCount((state) => {
              onHandleOnFilterSelect('countries', [...state, ev]);
              return [...state, ev];
            })
          }
          styles={reactSelectStyle}
          options={countries}
          placeholder="Search Country"
        />
      </div>

      {/* End Country filter section */}
      {/* ********************** */}
      {/* End Country filter section */}

      {/*  Date filter section */}
      {/* ********************** */}
      {/*  Date filter section */}

      <div className={style.filterGroup}>
        <h6 className={style.filterName}>Date</h6>

        <div className={style.selectedList}>
          {selectedDateFilter.label && (
            <button
              key={`date-li-${selectedRateFilter.label}`}
              type="submit"
              className={style.selectedItem}
              onClick={() => {
                setSelectedDateFilter({
                  val: '',
                  label: '',
                  searchKey: '',
                });

                setSelectedDateRange([
                  {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                  },
                ]);

                onHandleOnFilterSelect('date', '');
              }}
            >
              {selectedDateFilter.label}
              <span>
                <img src={closeIcon} alt="Close Icon" />
              </span>
            </button>
          )}
        </div>

        <div className="filter-calender">
          <DateRangePicker
            editableDateInputs
            onChange={(item) => {
              setSelectedDateRange([item.selection]);
              onDateFilter(item.selection);
            }}
            moveRangeOnFirstSelection={false}
            ranges={selectedDateRange}
            maxDate={moment().add(3, 'months').toDate()}
            minDate={moment().toDate()}
            className="calendar"
          />
        </div>
      </div>

      {/*  End Date filter section */}
      {/* ********************** */}
      {/*  End Date filter section */}
    </div>
  );
}

export default SearchFilter;
