/*
 * Add card component
 */
import React, { useState } from 'react';
import { Button } from '../button';
import { NativeInput, NativeInputValidation } from '../input';
import styles from './add-card.module.scss';
import closeIcon from '../../assets/close-cross.svg';
import mangoPayIcon from '../../assets/mangopay.png';
import padlock from '../../assets/padlock.svg';
import addCardIcon from '../../assets/add-card.svg';
import {
	MangoPayCardAddError,
	RegisterCard,
	formatMangopayErrorMessage,
} from '@conrati/utils';
import { useTranslation } from 'react-i18next';
import {
	expiryCleanUp,
	generalValidation,
	sizeValidation,
	validateExpiryDate,
} from './card-util';

interface CardFormValidation {
	name: NativeInputValidation;
	cardNumber: NativeInputValidation;
	cardExpirationDate: NativeInputValidation;
	cardCvx: NativeInputValidation;
}

interface AddCardProps {
	onClose: () => void;
	addCard: (cardDetails: RegisterCard) => Promise<void>;
}

function AddCard({ onClose, addCard }: AddCardProps): JSX.Element {
	const { t } = useTranslation();

	const [formValidation, setFormValidation] = useState<CardFormValidation>({
		name: {
			value: '',
			isValid: false,
			errorMessage: '',
		},
		cardNumber: {
			value: '',
			isValid: false,
			errorMessage: '',
		},
		cardExpirationDate: {
			value: '',
			isValid: false,
			errorMessage: '',
		},
		cardCvx: {
			value: '',
			isValid: false,
			errorMessage: '',
		},
	});

	const [addCardProcessing, setAddCardProcessing] = useState<boolean>(false);

	const [errorMessage, setErrorMessage] = useState<string>('');

	const onCardDetailsChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name, value } = event.target;

		let validationObj: NativeInputValidation = {
			value: '',
			isValid: false,
			errorMessage: '',
		};

		const trimValue = value.trim();

		if (name === 'name') {
			validationObj = generalValidation(
				trimValue,
				t('nativeUI.paymentCards.cardName')
			);
		}

		if (name === 'cardNumber') {
			validationObj = sizeValidation(
				trimValue,
				t('nativeUI.paymentCards.cardNumber'),
				trimValue.length >= 14 && trimValue.length <= 16
			);
		}

		if (name === 'cardCvx') {
			validationObj = sizeValidation(
				trimValue,
				'CVV',
				trimValue.length >= 3 && trimValue.length <= 4
			);
		}

		if (name === 'cardExpirationDate') {
			validationObj = validateExpiryDate(trimValue);
		}

		setFormValidation((currentState) => ({
			...currentState,
			[name]: validationObj,
		}));
	};

	const addCardToUserAccount = async (): Promise<void> => {
		try {
			setAddCardProcessing(true);
			setErrorMessage('');
			await addCard({
				cardNumber: formValidation.cardNumber.value,
				cardExpirationDate: expiryCleanUp(
					formValidation.cardExpirationDate.value
				),
				cardCvx: formValidation.cardCvx.value,
			});
			onClose();
		} catch (error) {
			const errorMesssage = formatMangopayErrorMessage(
				error as MangoPayCardAddError
			);
			setErrorMessage(errorMesssage);
		} finally {
			setAddCardProcessing(false);
		}
	};

	const isFormValid = (): boolean => {
		return (
			Object.values(formValidation).filter((field) => {
				return !field.isValid;
			}).length === 0
		);
	};

	return (
		<div className={styles.addCardRoot}>
			<div className={styles.closeButton}>
				<button
					className={styles.closeBtn}
					type="button"
					onClick={(): void => onClose()}
					data-testid="composite-home-components-native-signin-page-close-btn"
				>
					<img
						src={closeIcon}
						alt="Close Icon"
						data-testid="composite-home-components-native-signin-page-close-btn-icon"
					/>
				</button>
			</div>
			<div className={styles.addHeader}>
				<div
					className={styles.paymentCardsAddIcon}
					data-testid="ui-native-payment-cards-add-icon-container"
				>
					<img
						data-testid="ui-native-payment-cards-add-card-icon"
						src={addCardIcon}
						alt="Add Icon"
					/>
				</div>
				<span
					className={styles.paymentCardsFooterText}
					data-testid="ui-native-payment-cards-add-card-text"
				>
					{t('nativeUI.paymentCards.addNewCard')}
				</span>
			</div>

			<NativeInput
				type="text"
				name="name"
				placeholder={t('nativeUI.paymentCards.cardName')}
				onInputChange={onCardDetailsChange}
				validate={formValidation.name}
			/>
			<NativeInput
				type="text"
				name="cardNumber"
				placeholder={t('nativeUI.paymentCards.cardNumber')}
				onInputChange={onCardDetailsChange}
				validate={formValidation.cardNumber}
			/>

			<div className={styles.expiryBlock}>
				<NativeInput
					type="text"
					name="cardExpirationDate"
					value={formValidation.cardExpirationDate.value}
					placeholder={t('nativeUI.paymentCards.expiryDate')}
					onInputChange={onCardDetailsChange}
					validate={formValidation.cardExpirationDate}
				/>
				<NativeInput
					type="text"
					name="cardCvx"
					placeholder="CVV"
					onInputChange={onCardDetailsChange}
					validate={formValidation.cardCvx}
				/>
			</div>

			{errorMessage && (
				<p className={styles.errorMessage}>{errorMessage}</p>
			)}

			<div className={styles.securedPaymentContainer}>
				<div className={styles.paymentSecured}>
					<button
						type="button"
						onClick={(): void => {
							window.open('https://www.mangopay.com/privacy/');
						}}
					>
						<img
							src={mangoPayIcon}
							alt="mangopay"
							height="32px"
							width="151px"
						/>
					</button>
					<img
						src={padlock}
						alt="secure"
						height="27px"
						width="24px"
					/>
				</div>
				<div className={styles.paymentSecuredDescription}>
					Conrati entrust Mangopay (a secure third payment provider)
					to store and handle all payment related data
				</div>
			</div>

			<Button
				type="button"
				className={styles.addCardButton}
				loading={addCardProcessing}
				onClick={addCardToUserAccount}
				disabled={!isFormValid()}
			>
				{t('nativeUI.paymentCards.addCardButton')}
			</Button>
		</div>
	);
}

export { AddCard };
